import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ResourceService } from './resource.service';
import { MetadataService } from './metadata.service';

export function getJwtToken(): string
{
	return window.localStorage.getItem('baabek-dashboard-jwt');
}

@NgModule({
	imports: [
		HttpClientModule,
		JwtModule.forRoot({
			config: {
				tokenGetter: getJwtToken,
				whitelistedDomains: ['localhost:4200', 'baabek.sa', 'azizmb8.com']
			}
		})
	],
	providers: [
		AuthService,
		ResourceService,
		MetadataService
	],
	declarations: []
})
export class ResourcesModule { }
