import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../resources/interfaces';

@Pipe({
	name: 'matchesCategory'
})
export class MatchesCategoryPipe implements PipeTransform
{
	transform(products: Product[], category: string): Product[]
	{
		switch(category)
		{
			case 'vegs':
			{
				return products.filter(product => product.departmentID1 === '104');
			}
			case 'frozen':
			{
				return products.filter(product => product.departmentID1 === '115');
			}
			case 'icecream':
			{
				return products.filter(product => product.sectionID1 === '1501');
			}
			case 'other':
			{
				return products.filter(product => {  return product.departmentID1 !== '104' && product.departmentID1 !== '115' && product.sectionID1 !== '1501' });
			}
			default:
			{
				return products;
			}
		}
	}
}
