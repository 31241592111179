import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DirectionsService } from '../directions/directions.service';

@Injectable()
export class TranslatorService {

	private defaultLanguage: string = 'ar';

	private availablelangs = [
		{ code: 'en', text: 'English', direction: 'LTR' },
		{ code: 'ar', text: 'العربية', direction: 'RTL' },
	];

	constructor(
		public translate: TranslateService,
		public dirService: DirectionsService
	)
	{

		if (!translate.getDefaultLang())
			translate.setDefaultLang(this.defaultLanguage);

		this.useLanguage(this.defaultLanguage);

	}

	useLanguage(code: string = null)
	{
		this.translate.use(code);
		this.defaultLanguage = code;
		let lang = this.availablelangs.find(element => element.code == code );
		this.dirService.setDirection(lang.direction);
	}

	getAvailableLanguages()
	{
		return this.availablelangs;
	}
	
	getLang()
	{
		return this.defaultLanguage;
	}
	
	getDirection()
	{
		return this.availablelangs.find(element => element.code == this.defaultLanguage).direction;
	}
	
	changeLanguage()
	{
		this.useLanguage(this.defaultLanguage == 'en' ? 'ar' : 'en');
	}
}
