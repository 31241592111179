import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ToolboxService } from '../../../core/toolbox/toolbox.service';

@Component({
	selector: 'app-receipt',
	templateUrl: './receipt.component.html',
	styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit, OnChanges
{
	@Input() type;
	@Input() invoice;
	@Input() metadata;
	
	title;
	subtitle;
	number;
	district;
	date;
	time;
	products;
	discount;
	deliveryCharge;
	total;
	vatRate;
	vat;
	qrdata;
	
	constructor(
		private decimal: DecimalPipe,
		private tb: ToolboxService
	) { }

	ngOnInit()
	{
		switch (this.type)
		{
			case 'ORDER':
				this.displayOrderReceipt(this.invoice);
			break;
			case 'RETURNORDER':
				this.displayReturnOrderReceipt(this.invoice);
			break;
			case 'SALE':
				this.displayPOSReceipt(this.invoice);
			break;
			case 'RETURNSALE':
				this.displayPOSReturnsReceipt(this.invoice);
			break;
		}
	}
	
	ngOnChanges() { this.ngOnInit() }
	
	displayOrderReceipt(order)
	{
		this.title = 'فاتورة ضريبية مبسطة';
		this.subtitle = 'رقم الطلب: ';
		this.number = order.ID;
		this.district = this.tb.find(this.metadata.districts, order.address.districtID).name;
		this.date = order.date;
		const start = ('00' + ((+order.start + 11) % 12 + 1)).slice(-2) + ':00 ' + (+order.start >= 12 ? 'م' : 'ص');
		const end = ('00' + ((+order.end + 11) % 12 + 1)).slice(-2) + ':00 ' + (+order.end >= 12 ? 'م' : 'ص');
		this.time = start + ' - ' + end;
		this.products = this.prepareOrderProducts(order.products);
		this.discount = this.decimal.transform(order.receipt.discountPlusVat, '1.2-2');
		this.deliveryCharge = this.decimal.transform(order.receipt.deliveryChargePlusVat, '1.2-2');
		this.total = this.decimal.transform(order.receipt.grandTotal, '1.2-2');
		this.vatRate = order.receipt.vatRate * 100;
		this.vat = this.decimal.transform(order.receipt.vat, '1.2-2');
		this.qrdata = order.receipt.tvl;
	}
	
	displayReturnOrderReceipt(orderReturn)
	{
		this.title = 'فاتورة مردود مبيعات';
		this.subtitle = 'رقم الطلب: ';
		this.number = orderReturn.ID;
		this.date = orderReturn.date.substr(0, 10);
		this.time = orderReturn.date.substr(11, 8);
		this.products = this.preparePOSProducts(orderReturn.products);
		this.discount = this.decimal.transform(orderReturn.invoice.discountPlusVat, '1.2-2');
		this.deliveryCharge = this.decimal.transform(orderReturn.invoice.deliveryChargePlusVat, '1.2-2');
		this.total = this.decimal.transform(orderReturn.invoice.grandTotal, '1.2-2');
		this.vatRate = orderReturn.invoice.vatRate * 100;
		this.vat = this.decimal.transform(orderReturn.invoice.vat, '1.2-2');
		this.qrdata = orderReturn.invoice.tvl;
	}
	
	displayPOSReceipt(sale)
	{
		this.title = 'فاتورة ضريبية مبسطة';
		this.subtitle = 'رقم الفاتورة:';
		this.number = sale.ID;
		this.date = sale.date.substr(0, 10);
		this.time = sale.date.substr(11, 8);
		this.products = this.preparePOSProducts(sale.products);
		this.discount = this.decimal.transform(sale.invoice.discountPlusVat, '1.2-2');
		this.deliveryCharge = this.decimal.transform(sale.invoice.deliveryChargePlusVat, '1.2-2');
		this.total = this.decimal.transform(sale.invoice.grandTotal, '1.2-2');
		this.vatRate = sale.invoice.vatRate * 100;
		this.vat = this.decimal.transform(sale.invoice.vat, '1.2-2');
		this.qrdata = sale.invoice.tvl;
	}
	
	displayPOSReturnsReceipt(saleRetrun)
	{
		this.title = 'فاتورة مردود مبيعات';
		this.subtitle = 'رقم الفاتورة:';
		this.number = saleRetrun.ID;
		this.date = saleRetrun.date.substr(0, 10);
		this.time = saleRetrun.date.substr(11, 8);
		this.products = this.preparePOSProducts(saleRetrun.products);
		this.discount = this.decimal.transform(saleRetrun.invoice.discountPlusVat, '1.2-2');
		this.deliveryCharge = this.decimal.transform(saleRetrun.invoice.deliveryChargePlusVat, '1.2-2');
		this.total = this.decimal.transform(saleRetrun.invoice.grandTotal, '1.2-2');
		this.vatRate = saleRetrun.invoice.vatRate * 100;
		this.vat = this.decimal.transform(saleRetrun.invoice.vat, '1.2-2');
		this.qrdata = saleRetrun.invoice.tvl;
	}
	
	prepareOrderProducts(products)
	{
		let result = [];
		for (var i = 0; i < products.length; i++)
		{
			if (products[i].inCart > 0)
			{
				let product = {
					name: products[i].infoName + ' ' + products[i].varietyName + ' ' + products[i].levelName,
					size: products[i].size == '0' ? '' : products[i].size + ' ' + this.tb.find(this.metadata.packingUnits, products[i].packingUnitID).name,
					brand: this.tb.find(this.metadata.brands, products[i].brandID).name,
					quantity: products[i].inCart,
					price: this.decimal.transform(products[i].inCartTotalPlusVat, '1.2-2')
				}
				result.push(product)
			}
		}
		return result;
	}
	
	preparePOSProducts(products)
	{
		let result = [];
		for (var i = 0; i < products.length; i++)
		{
			let product = {
				name: products[i].infoName + ' ' + products[i].varietyName + ' ' + products[i].levelName,
				size: products[i].size == '0' ? '' : products[i].size + ' ' + this.tb.find(this.metadata.packingUnits, products[i].packingUnitID).name,
				brand: this.tb.find(this.metadata.brands, products[i].brandID).name,
				quantity: products[i].quantity,
				price: this.decimal.transform(products[i].totalPlusVat, '1.2-2')
			}
			result.push(product)
		}
		return result;
	}
}
