import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-validator-message',
	templateUrl: './validator-message.component.html',
	styleUrls: ['./validator-message.component.scss']
})
export class ValidatorMessageComponent implements OnInit
{
	@Input() formCtrl: FormControl;
	@Input() formGrp: boolean = false;
	
	constructor() { }
	
	ngOnInit() { }
	
}
