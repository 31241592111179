import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ToolboxService } from '../../../../core/toolbox/toolbox.service';

@Component({
	selector: 'app-mini-receipt',
	templateUrl: './mini-receipt.component.html',
	styleUrls: ['./mini-receipt.component.scss']
})
export class MiniReceiptComponent implements OnInit, OnChanges
{
	@Input() type;
	@Input() order;
	@Input() metadata;
	
	subtitle;
	number;
	district;
	date;
	time;
	
	constructor(
		private decimal: DecimalPipe,
		private tb: ToolboxService
	) { }

	ngOnInit()
	{
		this.subtitle = 'رقم الطلب: ';
		this.number = this.order.ID;
		this.district = this.tb.find(this.metadata.districts, this.order.address.districtID).name;
		this.date = this.order.date;
		const start = ('00' + ((+this.order.start + 11) % 12 + 1)).slice(-2) + ':00 ' + (+this.order.start >= 12 ? 'م' : 'ص');
		const end = ('00' + ((+this.order.end + 11) % 12 + 1)).slice(-2) + ':00 ' + (+this.order.end >= 12 ? 'م' : 'ص');
		this.time = start + ' - ' + end;
	}
	
	ngOnChanges() { this.ngOnInit() }
}
