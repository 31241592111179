import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Config } from './config';

@Injectable()
export class ResourceService
{
	constructor(private http: HttpClient) { }
	
	get(resource, id): Observable<any>
	{
		return this.http.get(`${Config.api}/${resource}/${id}`);
	}
	
	getParams(resource, id, params): Observable<any>
	{
		return this.http.get(`${Config.api}/${resource}/${id}`, { params: params });
	}
	
	create(resource, params): Observable<any>
	{
		return this.http.post(`${Config.api}/${resource}`, params);
	}
	
	update(resource, id, params): Observable<any>
	{
		return this.http.put(`${Config.api}/${resource}/${id}`, params);
	}
	
	delete(resource, id): Observable<any>
	{
		return this.http.delete(`${Config.api}/${resource}/${id}`);
	}
}
