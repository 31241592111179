import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster/angular2-toaster';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { QRCodeModule } from 'angularx-qrcode';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';

import { DecimalPipe, DatePipe } from '@angular/common';
import { AmPmPipe } from './pipes/am-pm.pipe';
import { CreditPipe } from './pipes/credit.pipe';
import { CurrencyPipe } from './pipes/currency.pipe';
import { MatchesCategoryPipe } from './pipes/matches-category.pipe';
import { NgSelectPipe } from './pipes/ng-select.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';

import { ProductImageLoaderComponent } from './components/product-image-loader/product-image-loader.component';
import { ValidatorMessageComponent } from './components/validator-message/validator-message.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ProductNameComponent } from './components/product-name/product-name.component';
import { ProductUnitComponent } from './components/product-unit/product-unit.component';
import { ResourceComponent } from './components/resource/resource.component';
import { DeliveryOptionComponent } from './components/delivery-option/delivery-option.component';
import { StatusComponent } from './components/status/status.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { MiniReceiptComponent } from './components/receipt/mini-receipt/mini-receipt.component';

// https://angular.io/styleguide#!#04-10
@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      TranslateModule,
      SweetAlert2Module.forRoot(),
      AccordionModule.forRoot(),
      AlertModule.forRoot(),
      ButtonsModule.forRoot(),
      CarouselModule.forRoot(),
      CollapseModule.forRoot(),
      DatepickerModule.forRoot(),
      BsDatepickerModule.forRoot(),
      BsDropdownModule.forRoot(),
      ModalModule.forRoot(),
      PaginationModule.forRoot(),
      ProgressbarModule.forRoot(),
      RatingModule.forRoot(),
      TabsModule.forRoot(),
      TimepickerModule.forRoot(),
      TooltipModule.forRoot(),
      PopoverModule.forRoot(),
      TypeaheadModule.forRoot(),
      ToasterModule,
      RouterModule,
			QRCodeModule
   ],
   providers: [
      ColorsService,
      DecimalPipe,
      DatePipe
   ],
   declarations: [
      FlotDirective,
      SparklineDirective,
      EasypiechartDirective,
      CheckallDirective,
      VectormapDirective,
      NowDirective,
      ScrollableDirective,
      JqcloudDirective,
      MatchesCategoryPipe,
      AmPmPipe,
      CreditPipe,
      CurrencyPipe,
      OrderByPipe,
      NgSelectPipe,
      ProductImageLoaderComponent,
      ValidatorMessageComponent,
      LoadingSpinnerComponent,
      ProductNameComponent,
      ProductUnitComponent,
      ResourceComponent,
      DeliveryOptionComponent,
      StatusComponent,
			ReceiptComponent,
			MiniReceiptComponent
   ],
   exports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      TranslateModule,
      SweetAlert2Module,
			QRCodeModule,
      RouterModule,
      AccordionModule,
      AlertModule,
      ButtonsModule,
      CarouselModule,
      CollapseModule,
      DatepickerModule,
      BsDatepickerModule,
      BsDropdownModule,
      ModalModule,
      PaginationModule,
      ProgressbarModule,
      RatingModule,
      TabsModule,
      TimepickerModule,
      TooltipModule,
      PopoverModule,
      TypeaheadModule,
      ToasterModule,
      FlotDirective,
      SparklineDirective,
      EasypiechartDirective,
      CheckallDirective,
      VectormapDirective,
      NowDirective,
      ScrollableDirective,
      JqcloudDirective,
      MatchesCategoryPipe,
      AmPmPipe,
      CreditPipe,
      CurrencyPipe,
      OrderByPipe,
      NgSelectPipe,
      ProductImageLoaderComponent,
      ValidatorMessageComponent,
      LoadingSpinnerComponent,
      ProductNameComponent,
      ProductUnitComponent,
      ResourceComponent,
      DeliveryOptionComponent,
      StatusComponent,
			ReceiptComponent,
			MiniReceiptComponent
   ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
   static forRoot(): ModuleWithProviders {
      return {
         ngModule: SharedModule
      };
   }
}
