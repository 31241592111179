import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, Router, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../resources/auth.service';

@Injectable({
   providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad
{
   constructor(
      private router: Router,
      private authService: AuthService
   ) { }
   
   canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean
   {
      if (this.authService.isLoggedIn) { return true; }
      
      let url: string = state.url;
      // Store the attempted URL for redirecting
      this.authService.redirectUrl = url;

      // Navigate to the login page with extras
      this.router.navigate(['/login']);
      return false;
   }
   
   canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean
   {
      return true;
   }
   
   canLoad(route: Route, segments: UrlSegment[]): boolean
   {
      return true;
   }
}
