import { Injectable } from '@angular/core';
declare var $: any;

@Injectable()
export class ToolboxService {
	vatRate: number = 0.15;
	
	constructor() { }
	
	find(resource: any[], ID)
	{
		return resource.find(item => item.ID === ID);
	}
	
	hasVeg(order)
	{
		if (order !== undefined)
		{
			var products = order.products;
			for (var i = 0; i < products.length; i++)
			{
				if (products[i].departmentID1 === '104')
					return true;
			}
		}
	}
	
	hasFrozen(order)
	{
		if (order !== undefined)
		{
			var products = order.products;
			for (var i = 0; i < products.length; i++)
			{
				if (products[i].departmentID1 === '115')
					return true;
			}
		}
	}
	
	hasIcecream(order)
	{
		if (order !== undefined)
		{
			var products = order.products;
			for (var i = 0; i < products.length; i++)
			{
				if (products[i].sectionID1 === '1501')
					return true;
			}
		}
	}
	
	isCompleted(order)
	{
		if (order !== undefined)
		{
			var products = order.products;
			for (var i = 0; i < products.length; i++)
			{
				if (+products[i].inCart < +products[i].quantity)
					return false;
			}
			return true;
		}
	}
	
	showImage(e)
	{
		$(e.target).parent().addClass('loaded');
	}
	
	showBackupImage(e)
	{
		$(e.target).attr("src", "assets/img/backup.jpg");
	}
	
	compareBySort(x, y)
	{
		if (+x.sort == +y.sort)
			return 0;
		if (+x.sort < +y.sort)
			return -1;
		else
			return 1;
	}
	
	findInvalidControls(form)
	{
		const invalid = [];
		const controls = form.controls;
		for (const name in controls)
		{
			if (controls[name].invalid)
			{
				invalid.push(name);
			}
		}
		return invalid;
	}
	
	createTransaction(accountID, referenceNo, description, debit, credit)
   {
      return {
         accountID: accountID,
         referenceNo: referenceNo,
         description: description,
         debit: debit,
         credit: credit
      }
   }
}
