import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { map } from "rxjs/operators";
import { TranslatorService } from '../../core/translator/translator.service';

@Pipe({
	name: 'currency'
})
export class CurrencyPipe implements PipeTransform
{
	constructor(
		private tr: TranslatorService,
		private number: DecimalPipe
	) { }
	
	transform(value: any, lang: string): Observable<string>
	{
		value = this.number.transform(value, '1.2-2');
		return this.tr.translate.get('invoice.CURRENCY').pipe(map((currency: string) => value + ' ' + currency))
	}
}
