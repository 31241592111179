import { Component, OnInit } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';

@Component({
   selector: 'app-layout',
   templateUrl: './layout.component.html',
   styleUrls: ['./layout.component.scss'],
   providers: [ToasterService]
})
export class LayoutComponent implements OnInit
{
	toasterConfig: ToasterConfig;
   
   constructor() { }
   
   ngOnInit()
   {
      this.toasterConfig = new ToasterConfig({positionClass: 'toast-bottom-right'});
   }
}
