import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-product-unit',
	templateUrl: './product-unit.component.html',
	styleUrls: ['./product-unit.component.scss']
})
export class ProductUnitComponent implements OnInit
{
	@Input() packingUnit;
	@Input() levelUnit;
	@Input() size;
	@Input() count;
	
	constructor() { }

	ngOnInit() { }
}
