import { Component, OnInit, Input } from '@angular/core';

import { Config } from '../../../resources/config';
declare var $: any;

@Component({
	selector: 'app-product-image-loader',
	templateUrl: './product-image-loader.component.html',
	styleUrls: ['./product-image-loader.component.scss']
})
export class ProductImageLoaderComponent implements OnInit
{
	@Input() ID: string;
	@Input() imageID: string;
	@Input() small: boolean;
	@Input() large: boolean;
	imageServer = Config.imagesServer;
	src: string;
	
	constructor() { }
	
	ngOnInit()
	{
		this.src = this.imageServer + '/' + this.ID + '_' + this.imageID + '/'+ this.determineSize() +'.jpg';
	}
	
	determineSize(): string
	{
		if (this.small)
			return 'main_small'
		else if (this.large)
			return 'main_large'
		else
			return 'main'
	}
	
	showImage(e)
	{
		$(e.target).parent().addClass('loaded');
	}
	
	showBackupImage(e)
	{
		$(e.target).attr("src", "assets/img/backup.jpg");
	}
	
}
