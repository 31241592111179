
const Home = {
   text: 'Home',
   translate: 'general.HOME',
   link: '/home',
   icon: 'fas fa-home'
};

const Accounting = {
   text: 'Accounting',
   translate: 'accounting.ACCOUNTING',
   link: '/accounting',
   icon: 'fas fa-money-bill-wave',
   submenu: [
      {
         text: 'Chart of Accounts',
         translate: 'accounting.CHARTOFACCOUNTS',
         link: '/accounting/accounts'
      },
      {
         text: 'Journal Entries',
         translate: 'accounting.JOURNALENTRIES',
         link: '/accounting/entries'
      },
      {
         text: 'Accounts Statements',
         translate: 'accounting.ACCOUNTSSTATEMENTS',
         link: '/accounting/statements'
      }
   ]
};

const Purchases = {
   text: 'Purchases',
   translate: 'purchases.PURCHASES',
   link: '/purchases',
   icon: 'fas fa-file-alt',
   submenu: [
      {
         text: 'Purchase Invoices',
         translate: 'purchases.PURCHASEINVOICES',
         link: '/purchases/invoices'
      },
      {
         text: 'Suppliers',
         translate: 'purchases.SUPPLIERS',
         link: '/purchases/suppliers'
      }
   ]
};

const Sales = {
   text: 'Sales',
   translate: 'sales.SALES',
   link: '/sales',
   icon: 'fas fa-shopping-basket',
   submenu: [
      {
         text: 'Orders',
         translate: 'sales.ORDERS',
         link: '/sales/orders'
      },
      {
         text: 'Sales Invoices',
         translate: 'sales.SALESINVOICES',
         link: '/sales/invoices'
      },
      {
         text: 'Delivery Options',
         translate: 'sales.DELIVERYOPTIONS',
         link: '/sales/deliveryOptions'
      },
      {
         text: 'Coupons',
         translate: 'sales.COUPONS',
         link: '/sales/coupons'
      },
      {
         text: 'POS',
         translate: 'sales.POINTOFSALES',
         link: '/sales/pos'
      }
   ]
};

const Inventory = {
   text: 'Inventory',
   translate: 'products.INVENTORY',
   link: '/inventory',
   icon: 'fas fa-cubes',
   submenu: [
      {
         text: 'Products',
         translate: 'products.PRODUCTS',
         link: '/inventory/products'
      },
      {
         text: 'Inventory Adjustments',
         translate: 'products.INVENTORYADJUSTMENTS',
         link: '/inventory/inventoryAdjustments'
      },
      {
         text: 'Inventory Movements',
         translate: 'products.INVENTORYMOVEMENTS',
         link: '/inventory/inventoryMovements'
      },
      {
         text: 'Brands',
         translate: 'products.BRANDS',
         link: '/inventory/brands'
      },
      {
         text: 'Departments',
         translate: 'products.DEPARTMENTS',
         link: '/inventory/departments'
      },
      {
         text: 'Packing Units',
         translate: 'products.PACKINGUNITS',
         link: '/inventory/packingUnits'
      },
      {
         text: 'Level Units',
         translate: 'products.LEVELUNITS',
         link: '/inventory/levelUnits'
      }
   ]
};

const Customers = {
   text: 'Customers',
   translate: 'customers.CUSTOMERS',
   link: '/customers',
   icon: 'fas fa-users'
};

const Settings = {
   text: 'Settings',
   translate: 'settings.SETTINGS',
   link: '/settings',
   icon: 'fas fa-cogs',
   submenu: [
      {
         text: 'Users',
         translate: 'settings.USERS',
         link: '/settings/users'
      },
      {
         text: 'Roles',
         translate: 'settings.ROLES',
         link: '/settings/roles'
      }
   ]
};

const headingMain = {
   text: 'Main Navigation',
   translate: 'general.MAINNAVIGATION',
   heading: true
};

export const menu = [
   headingMain,
   Home,
   Accounting,
   Purchases,
   Sales,
   Inventory,
   Customers,
   Settings
];
