import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { map } from "rxjs/operators";
import { TranslatorService } from '../../core/translator/translator.service';

@Pipe({
	name: 'credit'
})
export class CreditPipe implements PipeTransform
{
	constructor(
		private tr: TranslatorService,
		private number: DecimalPipe
	) { }
	
	transform(value: any, lang: string): Observable<string>
	{
		let newValue: any = Math.abs(value);
		newValue = this.number.transform(newValue, '1.2-2');
		if (value >= 0)
			return this.tr.translate.get('properties.DR').pipe(map((type: string) => newValue + ' ' + type));
		else if (value < 0)
			return this.tr.translate.get('properties.CR').pipe(map((type: string) => newValue + ' ' + type));
	}
}
