import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { map } from "rxjs/operators";
import { Config } from './config';
import { AuthResponse, Auth, JwtToken } from './interfaces';

@Injectable()
export class AuthService
{
	jwt: string;
	jwtToken: JwtToken;
	auth: Auth;
	// store the URL so we can redirect after logging in
	redirectUrl: string;
	
	constructor(
		private http: HttpClient,
		private jwtHelper: JwtHelperService,
		private router: Router
	)
	{
		this.redirectUrl = '';
	}
	
	login(username: string, password: string, remember: boolean): Observable<AuthResponse>
	{
		return this.http.post(`${Config.api}/authentication/user-login`, {username: username, password: password}).pipe(map((result: AuthResponse) => {
			if (result.status == 'success')
			{
				this.updateJwt(result.jwt);
				this.router.navigate([this.redirectUrl]);
				let self = this;
				if (!remember)
					window.addEventListener("beforeunload", function(event) { self.logout() });
			}
			return result;
		}));
	}
	
	logout(): void
	{
		window.localStorage.removeItem('baabek-dashboard-jwt');
		this.jwtToken = null;
		this.auth = null;
		this.router.navigate(['/login']);
	}
	
	get isLoggedIn(): boolean
	{
		if (window.localStorage.getItem('baabek-dashboard-jwt') !== null)
		{
			let jwt = window.localStorage['baabek-dashboard-jwt'];
			if (!this.jwtHelper.isTokenExpired(jwt))
			{
				this.jwtToken = this.jwtHelper.decodeToken(jwt);
				this.auth = this.jwtToken.auth;
				return true;
			}
		}
	}
	
	updateJwt(jwt): void
	{
		this.jwt = jwt;
		window.localStorage['baabek-dashboard-jwt'] = jwt;
		this.jwtToken = this.jwtHelper.decodeToken(jwt);
		this.auth = this.jwtToken.auth;
	};
}
