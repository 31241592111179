import { LayoutComponent } from '../layout/layout.component';
import { AuthGuard } from './auth.guard';

import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';

export const routes = [
   // lazy-loaded routes
   {
      path: '',
      component: LayoutComponent,
      canActivate: [AuthGuard],
      children: [
         { path: '', redirectTo: 'home', pathMatch: 'full' },
         { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
         { path: 'accounting', loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule) },
         { path: 'purchases', loadChildren: () => import('./purchases/purchases.module').then(m => m.PurchasesModule) },
         { path: 'sales', loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule) },
         { path: 'sales', loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule) },
         { path: 'inventory', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
         { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule) },
         { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) }
      ]
   },
   
   // Not lazy-loaded routes
   { path: 'login', component: LoginComponent },
   { path: 'register', component: RegisterComponent },
   { path: 'recover', component: RecoverComponent },
   { path: 'lock', component: LockComponent },
   { path: 'maintenance', component: MaintenanceComponent },
   { path: '404', component: Error404Component },
   { path: '500', component: Error500Component },
   
   // Not found
   { path: '**', redirectTo: 'home' }
];
