import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-status',
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit
{
	@Input() status;
	
	constructor() { }

	ngOnInit() { }
}
