import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SettingsService } from '../../../core/settings/settings.service';
import { TranslatorService } from '../../../core/translator/translator.service';
import { AuthService } from '../../../resources/auth.service';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit
{
   form: FormGroup;
   authMsg: string = '';
   
   constructor(
		public router: Router,
      public fb: FormBuilder,
      public tr: TranslatorService,
      public settings: SettingsService,
      public authService: AuthService
   ) { }
   
   ngOnInit()
   {
      this.form = this.createForm();
   }
   
   createForm()
   {
      return this.fb.group({
         'username': ['', Validators.required],
         'password': ['', Validators.required],
         'remember': true
      });
   }
   
   login()
   {
      if (this.form.invalid) return null;
      this.authMsg = '';
      this.authService.login(this.form.value.username, this.form.value.password, this.form.value.remember).subscribe(
         result => {
            if (result.status == 'error')
            {
               this.tr.translate.get('general.RESOURCENAME', {resource: result.message}).subscribe(text => this.authMsg = text);
            }
         },
         err => {
            this.tr.translate.get('text.LOSTCONNECTION').subscribe(text => this.authMsg = text);
         }
   );
   }
}
