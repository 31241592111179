import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import * as printer from 'print-html-element';
import { ToolboxService } from '../../core/toolbox/toolbox.service';
declare var $: any;

@Injectable()
export class PrinterService
{
	
	constructor(
		private number: DecimalPipe,
		private tb: ToolboxService
	) { }
	
	printElement(elemID)
	{
		printer.printElement(document.getElementById(elemID));
	}
	
	printOrderMiniReceipt(order, districts)
	{
		let title = 'فاتورة مبيعات';
		let subtitle = 'رقم الطلب: ';
		let number = order.ID;
		let district = this.tb.find(districts, order.address.districtID).name;
		let date = order.date;
		let start = ('00' + ((+order.start + 11) % 12 + 1)).slice(-2) + ':00 ' + (+order.start >= 12 ? 'م' : 'ص');
		let end = ('00' + ((+order.end + 11) % 12 + 1)).slice(-2) + ':00 ' + (+order.end >= 12 ? 'م' : 'ص');
		let deliveryOption = start + ' - ' + end;
		this.printMiniReceipt(title, subtitle, number, district, date, deliveryOption)
	}
	
	prepareOrderProducts(products, packingUnits, brands)
	{
		let result = [];
		for (var i = 0; i < products.length; i++)
		{
			if (products[i].inCart > 0)
			{
				let product = {
					name: products[i].infoName + ' ' + products[i].varietyName + ' ' + products[i].levelName,
					size: products[i].size == '0' ? '' : products[i].size + ' ' + this.tb.find(packingUnits, products[i].packingUnitID).name,
					brand: this.tb.find(brands, products[i].brandID).name,
					quantity: products[i].inCart,
					price: this.number.transform(products[i].inCartTotalPlusVat, '1.2-2')
				}
				result.push(product)
			}
		}
		return result;
	}
	
	preparePOSProducts(products, packingUnits, brands)
	{
		let result = [];
		for (var i = 0; i < products.length; i++)
		{
			let product = {
				name: products[i].infoName + ' ' + products[i].varietyName + ' ' + products[i].levelName,
				size: products[i].size == '0' ? '' : products[i].size + ' ' + this.tb.find(packingUnits, products[i].packingUnitID).name,
				brand: this.tb.find(brands, products[i].brandID).name,
				quantity: products[i].quantity,
				price: this.number.transform(products[i].totalPlusVat, '1.2-2')
			}
			result.push(product)
		}
		return result;
	}
	
	printMiniReceipt(title, subtitle, number, district, date, time)
	{
		let width = '410px';
		let fontSize = '16px';
		
		let districtTitle = 'الحي:';
		
		let dateTitle = 'التاريخ:';
		let timeTitle = 'الوقت:';
		
		let html = `
			<div style="width: ${width}; text-align: center; font-family: Arial, sans-serif; font-size: ${fontSize}; direction: rtl;">
				<div style="font-size: 120%;">
					<strong>${title}</strong>
				</div>
				<div style="font-size: 140%;">
					<strong>${subtitle} ${number}#</strong>
				</div>
				<div style="font-size: 160%; margin-top: 10px;">
					<strong>${districtTitle} ${district}</strong>
				</div>
				<div style="font-size: 100%; margin-top: 10px;">
					<strong>
						<span style="float: right;">${dateTitle}&nbsp;</span>
						<span style="float: right;">${date}</span>
						<span style="float: left;">${time}</span>
						<span style="float: left;">${timeTitle}&nbsp;</span>
					</strong>
				</div>
			</div>
		`;
		printer.printHtml(html, {pageTitle: 'From HTML String', printMode: 'iframe'});
	}
}
