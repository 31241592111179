import { Injectable } from '@angular/core';

const ltr = require('../../shared/styles/ltr.scss');
const rtl = require('../../shared/styles/rtl.scss');

@Injectable()
export class DirectionsService
{

	styleTag: any;

	constructor() {
		this.createStyle();
	}

	private createStyle() {
		const head = document.head || document.getElementsByTagName('head')[0];
		this.styleTag = document.createElement('style');
		this.styleTag.type = 'text/css';
		this.styleTag.id = 'appdirection';
		head.insertBefore(this.styleTag, head.childNodes[0]);
	}

	setDirection(name) {
		switch (name) {
			case 'LTR':
				this.injectStylesheet(ltr);
				break;
			case 'RTL':
				this.injectStylesheet(rtl);
				break;
		}
	}

	injectStylesheet(css) {
		this.styleTag.innerHTML = css;
	}

}
