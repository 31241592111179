import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map } from "rxjs/operators";
import { Config } from './config';
import { Metadata } from './interfaces';

@Injectable()
export class MetadataService
{
	metadata: Metadata;
	constructor(private http: HttpClient) { }
	
	getMetadata(): Observable<Metadata>
	{
		return this.http.get<Metadata>(`${Config.api}/metadata`).pipe(map(metadata => this.metadata = metadata));
	}
}
