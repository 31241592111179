import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-product-name',
	templateUrl: './product-name.component.html',
	styleUrls: ['./product-name.component.scss']
})
export class ProductNameComponent implements OnInit
{
	@Input() infoName;
	@Input() varietyName;
	@Input() levelName;
	@Input() brand;
	
	constructor() { }

	ngOnInit() { }
}
