import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToolboxService } from '../../../core/toolbox/toolbox.service';
import { MetadataService } from '../../../resources/metadata.service';
import { ResourceService } from '../../../resources/resource.service';
import { Metadata, Brand, Unit, Role, User, Supplier } from '../../../resources/interfaces';

@Component({
   selector: 'app-resource',
   templateUrl: './resource.component.html',
   styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit
{
   metadata: Metadata;
   resource: any[];
   resourceName: string;
   roleProperty: boolean;
   activeProperty: boolean;
   filteredResource: any[];
	query: string;
   active: string = 'ALL';
	
	constructor(
      private router: Router,
      private tb: ToolboxService,
      private metadataService: MetadataService,
      private resourceService: ResourceService
   ) { }
	
	ngOnInit()
	{
      this.metadataService.getMetadata().subscribe((metadata: Metadata) => this.metadata = metadata);
      this.resourceName = this.router.url.split('/')[2];
      this.resourceService.get(this.resourceName, 'collection').subscribe((resource: any[]) => {
         this.resource = resource == null ? [] : resource;
         this.filterResource();
      });
      this.roleProperty = this.resourceName == 'users';
      this.activeProperty =  this.resourceName == 'suppliers' || this.resourceName == 'brands' || this.resourceName == 'users';
	}
	
   ready()
   {
      return this.resource && this.metadata;
   }
   
	filterResource()
	{
      this.filteredResource = this.resource.filter((resource) => {
         return (resource.ID == this.query ||
            resource.name.match(this.query) ||
            resource.nameEn.toLowerCase().match(this.query.toLowerCase())) &&
            (!this.activeProperty || (this.activeProperty && (resource.active == this.active || this.active == 'ALL')))
      });
	}
}
