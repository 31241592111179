import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { map } from "rxjs/operators";
import { TranslatorService } from '../../core/translator/translator.service';

@Pipe({
	name: 'amPm'
})
export class AmPmPipe implements PipeTransform
{
	constructor(
		private tr: TranslatorService,
		private datePipe: DatePipe
	) { }
	
	transform(value: string, lang: string): Observable<string>
	{
		let hr = new Date(value).getHours();
		if (hr < 12)
			return this.tr.translate.get('general.AM').pipe(map((amPm: string) => this.datePipe.transform(value, 'yyyy-MM-dd hh:mm') + ' ' + amPm));
		else
			return this.tr.translate.get('general.PM').pipe(map((amPm: string) => this.datePipe.transform(value, 'yyyy-MM-dd hh:mm') + ' ' + amPm));
	}
}
