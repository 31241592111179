import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import { map } from "rxjs/operators";
import { TranslatorService } from '../../../core/translator/translator.service';

@Component({
   selector: 'app-delivery-option',
   templateUrl: './delivery-option.component.html',
   styleUrls: ['./delivery-option.component.scss']
})
export class DeliveryOptionComponent implements OnInit
{
   @Input() start;
   @Input() end;
   
   constructor(private tr: TranslatorService) { }

   ngOnInit() { }
   
   prepare()
   {
      let am, pm: string;
      return Observable
		.forkJoin(this.tr.translate.get('general.AM').pipe(map((res: string) => am = res)),
			this.tr.translate.get('general.PM').pipe(map((res: string) => pm = res)))
		.pipe(map(() => {
			let start = ('00' + ((+this.start + 11) % 12 + 1)).slice(-2) + ':00 ' + (+this.start >= 12 ? pm : am);
			let end = ('00' + ((+this.end + 11) % 12 + 1)).slice(-2) + ':00 ' + (+this.end >= 12 ? pm : am);
         return start + ' - ' + end;
		}));
   }
}
