import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import { map } from "rxjs/operators";
import { IOption } from 'ng-select';
import { TranslatorService } from '../../core/translator/translator.service';

@Pipe({
	name: 'ngSelect'
})
export class NgSelectPipe implements PipeTransform
{
	constructor(private tr: TranslatorService) { }
	
	transform(array: any[], lang: string): Observable<IOption[]>
	{
		return Observable.forkJoin(
			array.map(x => {
				return this.tr.translate.get('general.RESOURCENAME', {resource: x}).pipe(map(y => {
					return <IOption>{label: y, value: x.ID}
				}));
			})
		);
	}
}
